import { useState } from 'react';
import { FiEdit2, FiTrash2, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import BorderGradient from 'components/shared/BorderGradient/BorderGradient';
import Switch from 'components/shared/Switch';

interface Role {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    permissions: string[];
    createdAt: string;
}

interface RolesTableProps {
    roles: Role[];
    onUpdateRole: (role: Role) => void;
    onEdit: (role: Role) => void;
}

export default function RolesTable({ roles, onUpdateRole, onEdit }: RolesTableProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(roles.length / itemsPerPage);

    const handleEdit = (role: Role) => {
        onEdit(role);
    };

    const handleToggleStatus = (role: Role) => {
        onUpdateRole({
            ...role,
            isActive: !role.isActive
        });
    };

    const handleDelete = (role: Role) => {
        // Handle delete
    };

    const paginatedRoles = roles.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div className="space-y-4">
            <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
                                <th className="text-left py-3 px-4">Role Name</th>
                                <th className="text-left py-3 px-4">Description</th>
                                <th className="text-center py-3 px-4">Status</th>
                                <th className="text-right py-3 px-4">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedRoles.map((role) => (
                                <tr 
                                    key={role.id} 
                                    className="border-b border-white/[0.08] hover:bg-[#3A3A3A]"
                                >
                                    <td className="py-3 px-4">
                                        <span className="text-white">{role.name}</span>
                                    </td>
                                    <td className="py-3 px-4 text-gray-400 max-w-xl">
                                        <span className="line-clamp-1">{role.description}</span>
                                    </td>
                                    <td className="py-3 px-4">
                                        <div className="flex justify-center">
                                            <Switch
                                                checked={role.isActive}
                                                onChange={() => handleToggleStatus(role)}
                                            />
                                        </div>
                                    </td>
                                    <td className="py-3 px-4 text-right">
                                        <div className="flex items-center justify-end gap-2">
                                            <button 
                                                onClick={() => handleEdit(role)}
                                                className="p-2 hover:bg-white/[0.08] rounded-lg text-gray-400 hover:text-white"
                                            >
                                                <FiEdit2 size={16} />
                                            </button>
                                            <button 
                                                onClick={() => handleDelete(role)}
                                                className="p-2 hover:bg-white/[0.08] rounded-lg text-gray-400 hover:text-red-400"
                                            >
                                                <FiTrash2 size={16} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination */}
            <div className="flex items-center justify-between px-2">
                <div className="text-sm text-gray-400">
                    Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, roles.length)} of {roles.length} results
                </div>
                <div className="flex items-center gap-2">
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="p-2 text-gray-400 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <FiChevronLeft size={20} />
                    </button>
                    <div className="flex items-center gap-1">
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                            <button
                                key={page}
                                onClick={() => setCurrentPage(page)}
                                className={`
                                    w-8 h-8 rounded-lg text-sm font-medium
                                    ${currentPage === page
                                        ? 'bg-[#2A2A2A] text-white'
                                        : 'text-gray-400 hover:text-white'
                                    }
                                `}
                            >
                                {page}
                            </button>
                        ))}
                    </div>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="p-2 text-gray-400 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <FiChevronRight size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
} 