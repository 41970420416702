import { useState, useMemo } from 'react';
import Switch from 'components/shared/Switch';
import {  FiPlus, FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Permission, permissions } from '@/lib/constants';
import SearchBar from 'components/shared/SearchBar';
import PermissionModal from './PermissionModal';
import { Tooltip } from 'react-tooltip';

export default function PermissionsContent() {
    // const [selectedRole, setSelectedRole] = useState('Super Admin');
    const [permissionsData, setPermissionsData] = useState(permissions);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingPermission, setEditingPermission] = useState<Permission | null>(null);

    // Add search filter functionality
    const filteredPermissions = useMemo(() => {
        if (!searchTerm) return permissionsData;
        
        return permissionsData.filter(module => 
            module.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            module.functionalities.some(func => 
                func.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [permissionsData, searchTerm]);

    const handleModuleToggle = (moduleId: string) => {
        setPermissionsData(prevData => {
            return prevData.map(module => {
                if (module.id === moduleId) {
                    const newEnabled = !module.isEnabled;
                    return {
                        ...module,
                        isEnabled: newEnabled,
                        functionalities: module.functionalities.map(func => ({
                            ...func,
                            isEnabled: newEnabled // Toggle all functionalities based on module state
                        }))
                    };
                }
                return module;
            });
        });
    };
    
    const handleFunctionalityToggle = (moduleId: string, functionalityId: string) => {
        setPermissionsData(prevData => {
            return prevData.map(module => {
                if (module.id === moduleId) {
                    const newFunctionalities = module.functionalities.map(func => {
                        if (func.id === functionalityId) {
                            return { ...func, isEnabled: !func.isEnabled };
                        }
                        return func;
                    });
    
                    // Ensure module's isEnabled is true if any functionality is enabled, and false if none are
                    const anyFunctionalitiesEnabled = newFunctionalities.some(f => f.isEnabled);
    
                    return {
                        ...module,
                        isEnabled: anyFunctionalitiesEnabled, // Only disable module if no functionalities are enabled
                        functionalities: newFunctionalities
                    };
                }
                return module;
            });
        });
    };
    
    const handleUpdate = () => {
        // Handle update logic here
        console.log('Updating permissions...');
    };

    const shouldShowTooltip = (text: string) => {
        return text.length > 35; // Show tooltip if text is longer than 50 characters
    };

    const truncateText = (text: string) => {
        if (shouldShowTooltip(text)) {
            return text.slice(0, 35) + '...';
        }
        return text;
    };

    const handleEdit = (module: Permission) => {
        setEditingPermission(module);
        setShowModal(true);
    };

    const handleDelete = (moduleId: string) => {
        // Add delete confirmation logic here
        setPermissionsData(prevData => prevData.filter(module => module.id !== moduleId));
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <SearchBar
                    value={searchTerm}
                    onChange={setSearchTerm}
                    placeholder="Search permissions..."
                />
                <button
                    onClick={() => setShowModal(true)}
                    className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-4 py-2 text-white"
                >
                    <FiPlus /> Add Permission
                </button>
            </div>

            <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
                <table className="w-full border-collapse">
                    <thead>
                        <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
                            <th className="text-left py-3 px-4" style={{ width: '15%' }}>Feature</th>
                            <th className="text-center py-3 px-4" style={{ width: '10%' }}>Check All</th>
                            <th className="text-left py-3 px-4" style={{ width: '20%' }}>Functionality</th>
                            <th className="text-left py-3 px-4" style={{ width: '25%' }}>Description</th>
                            <th className="text-center py-3 px-4" style={{ width: '20%' }}>Access</th>
                            <th className="text-center py-3 px-4" style={{ width: '10%' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPermissions.map((module, index) => (
                            <tr key={module.id} className={index !== permissionsData.length - 1 ? 'border-b border-white/[0.08]' : ''}>
                                <td className="align-top">
                                    <div className="py-3 px-4">
                                        <span className="text-white">{module.name}</span>
                                    </div>
                                </td>
                                <td className="align-top">
                                    <div className="py-3 px-4 flex justify-center">
                                        <Switch
                                            checked={module.isEnabled}
                                            onChange={() => handleModuleToggle(module.id)}
                                        />
                                    </div>
                                </td>
                                <td className="align-top">
                                    <div className="space-y-2 py-3">
                                        {module.functionalities.map((functionality) => (
                                            <div 
                                                key={functionality.id} 
                                                className="px-4 py-2 text-gray-400"
                                            >
                                                <div 
                                                    className={`max-w-full ${shouldShowTooltip(functionality.name) ? 'truncate' : ''}`}
                                                    {...(shouldShowTooltip(functionality.name) && {
                                                        'data-tooltip-id': 'functionality-tooltip',
                                                        'data-tooltip-content': functionality.name
                                                    })}
                                                >
                                                    {truncateText(functionality.name)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className="align-top">
                                    <div className="space-y-2 py-3">
                                        {module.functionalities.map((functionality) => (
                                            <div 
                                                key={functionality.id} 
                                                className="px-4 py-2 text-gray-400"
                                            >
                                                <div 
                                                    className={`max-w-full ${shouldShowTooltip(functionality.description) ? 'truncate' : ''}`}
                                                    {...(shouldShowTooltip(functionality.description) && {
                                                        'data-tooltip-id': 'description-tooltip',
                                                        'data-tooltip-content': functionality.description
                                                    })}
                                                >
                                                    {truncateText(functionality.description)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className="align-top">
                                    <div className="space-y-2 py-3">
                                        {module.functionalities.map((functionality) => (
                                            <div key={functionality.id} className="px-4 py-2 flex justify-center">
                                                <Switch
                                                    checked={functionality.isEnabled}
                                                    onChange={() => handleFunctionalityToggle(module.id, functionality.id)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td className="align-top">
                                    <div className="py-3 px-4 flex justify-center gap-2">
                                        <button
                                            onClick={() => handleEdit(module)}
                                            className="p-2 text-gray-400 hover:text-blue-400 transition-colors"
                                        >
                                            <FiEdit2 size={16} />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(module.id)}
                                            className="p-2 text-gray-400 hover:text-red-400 transition-colors"
                                        >
                                            <FiTrash2 size={16} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Tooltips */}
            <Tooltip 
                id="functionality-tooltip" 
                place="top"
                className="max-w-md bg-[#1A1A1A] border border-white/[0.08] !opacity-100"
            />
            <Tooltip 
                id="description-tooltip" 
                place="top"
                className="max-w-md bg-[#1A1A1A] border border-white/[0.08] !opacity-100"
            />

            <PermissionModal
                isOpen={showModal}
                onClose={() => {
                    setShowModal(false);
                    setEditingPermission(null);
                }}
                mode={editingPermission ? 'edit' : 'create'}
                initialData={editingPermission}
            />

            {/* Action Buttons */}
            <div className="flex justify-end gap-3">
                <button
                    onClick={() => window.location.reload()}
                    className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
                >
                    Cancel
                </button>
                <button
                    onClick={handleUpdate}
                    className="px-4 py-2 bg-[#2A2A2A] text-white rounded-lg hover:bg-[#333333] transition-colors"
                >
                    Update
                </button>
            </div>
        </div>
    );
} 