import * as Switch from '@radix-ui/react-switch';
import './Switch.css';

interface SwitchProps {
    checked: boolean;
    onChange: () => void;
}

export default function SwitchComponent({ checked, onChange }: SwitchProps) {
    return (
        <Switch.Root
            checked={checked}
            onCheckedChange={onChange}
            className="switch-root"
        >
            <Switch.Thumb className="switch-thumb" />
        </Switch.Root>
    );
}