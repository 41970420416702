import * as Dialog from '@radix-ui/react-dialog';
import { FiX, FiPlus, FiTrash } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import BorderGradient from 'components/shared/BorderGradient/BorderGradient';
import Switch from 'components/shared/Switch';

interface PermissionModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode: 'create' | 'edit';
  initialData?: any;
}

export default function PermissionModal({ isOpen, onClose, mode, initialData }: PermissionModalProps) {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    isEnabled: initialData?.isEnabled || true,
    functionalities: initialData?.functionalities || [{ name: '', description: '', isEnabled: true }]
  });

  const addFunctionality = () => {
    setFormData(prev => ({
      ...prev,
      functionalities: [...prev.functionalities, { name: '', description: '', isEnabled: true }]
    }));
  };

  const removeFunctionality = (index: number) => {
    setFormData(prev => ({
      ...prev,
      functionalities: prev.functionalities.filter((_: any, i: number) => i !== index)
    }));
  };

  useEffect(() => {
    if (mode === 'edit' && initialData) {
      setFormData(initialData);
    }
    else {
      setFormData({
        name: '',
        isEnabled: true,
        functionalities: [{ name: '', description: '', isEnabled: true }]
      });
    }
  }, [mode, initialData]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl">
          <BorderGradient className="rounded-lg">
            <div className="bg-[#1A1A1A] rounded-lg flex flex-col" style={{ maxHeight: 'calc(90vh - 100px)' }}>
              <div className="flex items-center justify-between p-6 border-b border-white/[0.08]">
                <Dialog.Title className="text-lg font-semibold text-white">
                  {mode === 'create' ? 'Add Permission' : 'Edit Permission'}
                </Dialog.Title>
                <button onClick={onClose} className="text-gray-400 hover:text-white">
                  <FiX size={24} />
                </button>
              </div>

              <div className="p-6 space-y-6 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 250px)' }}>
                <div className="flex gap-4 items-start">
                  <div className="flex-1">
                    <label className="block text-sm text-gray-400 mb-2">Feature Name</label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={e => setFormData({ ...formData, name: e.target.value })}
                      className="w-full bg-[#2A2A2A] rounded-lg px-4 py-2 text-white border border-white/[0.08]"
                    />
                  </div>
                  <div className="pt-8">
                    <Switch
                      checked={formData.isEnabled}
                      onChange={() => setFormData(prev => ({ ...prev, isEnabled: !prev.isEnabled }))}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center mb-4">
                    <label className="text-sm text-gray-400">Functionalities</label>
                    <button
                      onClick={addFunctionality}
                      className="flex items-center gap-2 text-blue-400 hover:text-blue-300"
                    >
                      <FiPlus size={16} /> Add Functionality
                    </button>
                  </div>
                  
                  <div className="space-y-4">
                    {formData.functionalities.map((func: any, index: number) => (
                      <div key={index} className="flex gap-4 items-start">
                        <div className="flex-1">
                          <div className="flex gap-4 mb-2">
                            <input
                              placeholder="Functionality name"
                              value={func.name}
                              onChange={e => {
                                const newFunctionalities = [...formData.functionalities];
                                newFunctionalities[index].name = e.target.value;
                                setFormData({ ...formData, functionalities: newFunctionalities });
                              }}
                              className="flex-1 bg-[#2A2A2A] rounded-lg px-4 py-2 text-white border border-white/[0.08]"
                            />
                            <Switch
                              checked={func.isEnabled}
                              onChange={() => {
                                const newFunctionalities = [...formData.functionalities];
                                newFunctionalities[index].isEnabled = !func.isEnabled;
                                setFormData({ ...formData, functionalities: newFunctionalities });
                              }}
                            />
                          </div>
                          <textarea
                            placeholder="Description"
                            value={func.description}
                            onChange={e => {
                              const newFunctionalities = [...formData.functionalities];
                              newFunctionalities[index].description = e.target.value;
                              setFormData({ ...formData, functionalities: newFunctionalities });
                            }}
                            className="w-full bg-[#2A2A2A] rounded-lg px-4 py-2 text-white border border-white/[0.08] h-20"
                          />
                        </div>
                        <button
                          onClick={() => removeFunctionality(index)}
                          className="p-2 text-red-400 hover:text-red-300"
                        >
                          <FiTrash size={16} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-3 p-6 border-t border-white/[0.08] bg-[#1A1A1A]">
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-gray-400 hover:text-white"
                >
                  Cancel
                </button>
                <button
                  onClick={onClose}
                  className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-4 py-2 text-white"
                >
                  {mode === 'create' ? 'Add Permission' : 'Update Permission'}
                </button>
              </div>
            </div>
          </BorderGradient>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
} 