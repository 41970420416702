import { useState } from 'react';
import { FiMoreVertical, FiEdit2 } from 'react-icons/fi';
import BorderGradient from './BorderGradient/BorderGradient';
import classNames from 'utilities/ClassNames';

interface StatWidgetProps {
  label: string;
  value: string | number;
  change?: string;
  onUpdate?: (newData: { label: string; value: string; change: string }) => void;
}

export const StatWidget = ({ label, value, change, onUpdate }: StatWidgetProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    label,
    value: String(value),
    change: change || ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onUpdate?.(formData);
    setShowModal(false);
    setShowMenu(false);
  };

  return (
    <div className="bg-[#1A1A1A] rounded-xl p-6 border border-white/[0.08] relative">
      {/* Three dots menu */}
      <div className="absolute top-4 right-4">
        <button 
          onClick={() => setShowMenu(!showMenu)}
          className="p-1.5 hover:bg-white/[0.08] rounded-lg text-gray-400"
        >
          <FiMoreVertical size={16} />
        </button>
        
        {/* Dropdown menu */}
        {showMenu && (
          <>
            <div 
              className="fixed inset-0 z-10" 
              onClick={() => setShowMenu(false)}
            />
            <div className="absolute right-0 mt-2 w-36 bg-[#2A2A2A] rounded-lg border border-white/[0.08] shadow-lg z-20">
              <button
                onClick={() => {
                  setShowModal(true);
                  setShowMenu(false);
                }}
                className="flex items-center gap-2 w-full px-4 py-2 text-sm text-gray-400 hover:bg-white/[0.08] hover:text-white"
              >
                <FiEdit2 size={14} />
                Edit Widget
              </button>
            </div>
          </>
        )}
      </div>

      {/* Widget content */}
      <div className="text-gray-400 text-sm mb-2">{label}</div>
      <div className="flex items-center gap-2">
        <span className="text-white text-2xl font-medium">{value}</span>
        {change && (
          <span className={classNames(
            'px-2 py-0.5 text-xs rounded-full',
            change === '0%' ? 'bg-white/[0.08] text-gray-400' : 
            change.startsWith('+') ? 'bg-[#1C3829] text-[#4ADE80]' : 
            'bg-[#e11d486b] text-red-500'
          )}>
            {change}
          </span>
        )}
      </div>

      {/* Edit Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <BorderGradient>
            <div className="bg-[#1A1A1A] p-6 rounded-lg w-full max-w-md">
              <h2 className="text-xl text-white mb-6">Edit Widget</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm text-gray-400 mb-1">Label</label>
                  <input
                    type="text"
                    value={formData.label}
                    onChange={e => setFormData({ ...formData, label: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-lg px-4 py-2 text-white"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-400 mb-1">Value</label>
                  <input
                    type="text"
                    value={formData.value}
                    onChange={e => setFormData({ ...formData, value: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-lg px-4 py-2 text-white"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-400 mb-1">Change (%)</label>
                  <input
                    type="text"
                    value={formData.change}
                    onChange={e => setFormData({ ...formData, change: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-lg px-4 py-2 text-white"
                    placeholder="e.g. +15% or -10%"
                  />
                </div>
                <div className="flex justify-end gap-3 pt-4">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 text-gray-400 hover:text-white"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </BorderGradient>
        </div>
      )}
    </div>
  );
}; 