import { useState, useEffect } from 'react';
import { FiChevronDown, FiChevronUp, FiCalendar } from 'react-icons/fi';
import OrganizationModal from '../Modal/OrganizationModal';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SearchBar from 'components/shared/SearchBar';

interface OrganizationFiltersProps {
  setRefreshMode: (value: string) => void;
  setFilters: (filters: any) => void;
  filters: {
    organization: string;
    subscriptionType: string;
    billingPeriod: string;
    paymentStatus: string;
    startDate: Date | null;
    endDate: Date | null;
    search: string;
  };
}

interface DropdownOption {
  id: string | number;
  label: string;
}

export default function OrganizationFilters({ setRefreshMode, setFilters, filters }: OrganizationFiltersProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const organizations: DropdownOption[] = [
    { id: 1, label: 'Acme Corporation' },
    { id: 2, label: 'TechStart Solutions' },
    { id: 3, label: 'Global Innovations' },
    { id: 4, label: 'Innovation Labs' }
  ];

  const subscriptionTypes: DropdownOption[] = [
    { id: 'basic', label: 'Basic' },
    { id: 'premium', label: 'Premium' },
    { id: 'enterprise', label: 'Enterprise' }
  ];

  const billingPeriods: DropdownOption[] = [
    { id: 'monthly', label: 'Monthly' },
    { id: 'quarterly', label: 'Quarterly' },
    { id: 'yearly', label: 'Yearly' }
  ];

  const paymentStatuses: DropdownOption[] = [
    { id: 'paid', label: 'Paid' },
    { id: 'pending', label: 'Pending' },
    { id: 'overdue', label: 'Overdue' }
  ];

  const handleDropdownClick = (dropdownName: string) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleOptionSelect = (dropdownName: string, value: string) => {
    setFilters((prev:any) => ({ ...prev, [dropdownName]: value }));
    setOpenDropdown(null);
  };

  const clearFilters = () => {
    setFilters({
      organization: '',
      subscriptionType: '',
      billingPeriod: '',
      paymentStatus: '',
      startDate: null,
      endDate: null,
      search: ''
    });
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setFilters((prev: any) => ({
      ...prev,
      search: value
    }));
  };

  useEffect(() => {
    setFilters((prev: any) => ({
      ...prev,
      search: searchTerm
    }));
  }, [searchTerm]);

  const Dropdown = ({ 
    name, 
    options, 
    label 
  }: { 
    name: string; 
    options: DropdownOption[]; 
    label: string;
  }) => (
    <div className="relative">
      <button
        onClick={() => handleDropdownClick(name)}
        className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-3 py-2 text-gray-400 hover:bg-[#3A3A3A]"
      >
        {filters[name as keyof typeof filters]?.toString() || label}
        {openDropdown === name ? <FiChevronUp /> : <FiChevronDown />}
      </button>

      {openDropdown === name && (
        <>
          <div
            className="fixed inset-0 z-10"
            onClick={() => setOpenDropdown(null)}
          />
          <div className="absolute top-full left-0 mt-2 w-48 bg-[#2A2A2A] rounded-md shadow-lg z-20 py-1 border border-white/[0.08]">
            {options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionSelect(name, option.label)}
                className="w-full text-left px-4 py-2 text-gray-400 hover:bg-[#3A3A3A] hover:text-white"
              >
                {option.label}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-3">
          <SearchBar
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search organizations..."
          />
          
          <div className="relative">
            <button
              onClick={() => setShowDatePicker(!showDatePicker)}
              className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-3 py-2 text-gray-400 hover:bg-[#3A3A3A]"
            >
              <FiCalendar />
              {(filters.startDate && filters.endDate)
                ? `${new Date(filters.startDate).toLocaleDateString()} - ${new Date(filters.endDate).toLocaleDateString()}`
                : 'Select Duration'
              }
            </button>
            
            {showDatePicker && (
              <>
                <div
                  className="fixed inset-0 z-10"
                  onClick={() => setShowDatePicker(false)}
                />
                <div className="absolute top-full left-0 mt-2 z-20 bg-[#2A2A2A] rounded-lg p-4 border border-white/[0.08]">
                  <DatePicker
                    selectsRange={true}
                    startDate={filters.startDate}
                    endDate={filters.endDate}
                    onChange={(update: [Date | null, Date | null]) => {
                      setFilters((prev: any) => ({
                        ...prev,
                        startDate: update[0],
                        endDate: update[1]
                      }));
                      if (update[0] && update[1]) {
                        setShowDatePicker(false);
                      }
                    }}
                    inline
                  />
                </div>
              </>
            )}
          </div>
          
          <Dropdown
            name="organization"
            options={organizations}
            label="Organization Name"
          />
          
          <Dropdown
            name="subscriptionType"
            options={subscriptionTypes}
            label="Subscription type"
          />
          
          <Dropdown
            name="billingPeriod"
            options={billingPeriods}
            label="Billing Period"
          />
          
          <Dropdown
            name="paymentStatus"
            options={paymentStatuses}
            label="Payment Status"
          />

          {Object.values(filters).some(Boolean) && (
            <button 
              onClick={clearFilters}
              className="text-red-400 hover:text-red-300"
            >
              Clear all
            </button>
          )}
        </div>

        <div className="flex items-center gap-3">
          <button className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-4 py-2 text-white">
            ↓ Export
          </button>
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 rounded-md px-4 py-2 text-white"
          >
            + Add Organization
          </button>
        </div>
      </div>

      <OrganizationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode="create"
        setRefreshMode={setRefreshMode}
      />
    </>
  );
} 