import { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import TicketModal from './TicketModal';
import TicketDetailsModal from './TicketDetailsModal';
import { Ticket } from '@/lib/constants';


const assignees = [
    { id: 'select', label: 'Select' },
    { id: 'john_smith', label: 'John Smith' },
    { id: 'sarah_parker', label: 'Sarah Parker' },
    { id: 'mike_johnson', label: 'Mike Johnson' },
    { id: 'emily_brown', label: 'Emily Brown' },
    { id: 'david_wilson', label: 'David Wilson' }
];

export default function TicketsTable({ tickets }: { tickets: Ticket[] }) {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    

    // Calculate pagination
    const totalPages = Math.ceil(tickets.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentTickets = tickets.slice(startIndex, endIndex);

    const [editTicket, setEditTicket] = useState<Ticket | null>(null);

    const handleEdit = (ticket: Ticket) => {
        setEditTicket(ticket);
        setIsCreateModalOpen(true);
        setSelectedTicket(null); 
    };

    return (
        <div className="pb-3">
            <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
                <table className="w-full">
                    <thead>
                        <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
                            <th className="text-left p-4">Ticket ID</th>
                            <th className="text-left p-4">Status</th>
                            <th className="text-left p-4">Customer</th>
                            <th className="text-left p-4">Ticket name</th>
                            <th className="text-left p-4">Description</th>
                            <th className="text-left p-4">Added on</th>
                            <th className="text-left p-4">Assigned to</th>
                            <th className="text-left p-4">Last updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentTickets.map((ticket) => (
                            <tr
                                key={ticket.id}
                                onClick={() => setSelectedTicket(tickets[0])}
                                className="border-b border-white/[0.08] hover:bg-[#3A3A3A] cursor-pointer"
                            >
                                <td className="p-4 text-gray-400">{ticket.id}</td>
                                <td className="p-4">
                                    <span
                                        className={`px-2 py-1 rounded-full text-xs font-medium
      ${ticket.status === 'Open' ? 'bg-[#1C3829] text-[#4ADE80]' :
                                                ticket.status === 'Assigned' ? 'bg-[#1E3A8A] text-[#60A5FA]' :
                                                    ticket.status === 'Inprogress' ? 'bg-[#92400E] text-[#FBBF24]' :
                                                        ticket.status === 'Resolved' ? 'bg-[#065F46] text-[#34D399]' :
                                                            ticket.status === 'Closed' ? 'bg-[#7F1D1D] text-[#F87171]' :
                                                                'bg-[#374151] text-[#9CA3AF]' // Default fallback
                                            }`}
                                    >
                                        {ticket.status}
                                    </span>
                                </td>
                                <td className="p-4 text-gray-400">{ticket.customer}</td>
                                <td className="p-4 text-white">{ticket.name}</td>
                                <td className="p-4 text-gray-400 max-w-[300px]">
                                    <div className="truncate whitespace-normal overflow-hidden text-sm leading-5" style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                                        {ticket.description}
                                    </div>
                                </td>
                                <td className="p-4 text-gray-400">{ticket.addedOn}</td>
                                <td className="p-4">
                                    <select 
                                        className="bg-[#2A2A2A] border border-white/[0.08] rounded px-2 py-1 text-gray-400"
                                        onClick={(e) => e.stopPropagation()}
                                        value={ticket.assignedTo}
                                    >
                                        {assignees.map((assignee) => (
                                            <option key={assignee.id} value={assignee.id}>
                                                {assignee.label}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td className="p-4 text-gray-400">{ticket.lastUpdated}</td>
                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="flex justify-between items-center mt-4 px-4">
                <div className="text-sm text-gray-400">
                    Showing {startIndex + 1} to {Math.min(endIndex, tickets.length)} of {tickets.length} entries
                </div>
                <div className="flex items-center gap-2">
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="p-2 text-gray-400 hover:text-white disabled:opacity-50"
                    >
                        <FiChevronLeft />
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                        <button
                            key={page}
                            onClick={() => setCurrentPage(page)}
                            className={`w-8 h-8 rounded-lg ${
                                currentPage === page
                                    ? 'bg-blue-600 text-white'
                                    : 'text-gray-400 hover:text-white'
                            }`}
                        >
                            {page}
                        </button>
                    ))}
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="p-2 text-gray-400 hover:text-white disabled:opacity-50"
                    >
                        <FiChevronRight />
                    </button>
                </div>
            </div>

            <TicketDetailsModal
                isOpen={!!selectedTicket}
                onClose={() => setSelectedTicket(null)}
                ticket={selectedTicket}
                onEdit={handleEdit}
           
            />

            <TicketModal
                isOpen={isCreateModalOpen}
                onClose={() => {
                    setIsCreateModalOpen(false);
                    setSelectedTicket(null);
                }}
                initialData={editTicket}
                mode={editTicket ? 'edit' : 'create'}
            />
        </div>
    );
} 