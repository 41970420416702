import { useEffect, useState } from 'react';
import { FiX, FiEye, FiTrash2 } from 'react-icons/fi';
import DropZone from './DropZone';
import BorderGradient from 'components/shared/BorderGradient/BorderGradient';

interface Ticket {
  id: string;
  status: string;
  customer: string;
  name: string;
  description: string;
  addedOn: string;
  assignedTo: string;
  lastUpdated: string;
  attachments?: File[];
  email?: string;
  priority?: string;
  escalationDetails?: {
    isEscalated: boolean;
    reason?: string;
  };
}

interface TicketModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialData?: Ticket | null;
  mode: 'create' | 'edit';
  onAfterClose?: () => void;
}

export default function TicketModal({ isOpen, onClose, initialData, mode, onAfterClose }: TicketModalProps) {
  const [formData, setFormData] = useState({
    customerName: initialData?.customer || '',
    email: initialData?.email || '',
    subject: initialData?.name || '',
    description: initialData?.description || '',
    attachments: [] as File[],
    assignedTo: initialData?.assignedTo || '',
    status: initialData?.status || '',
    priority: initialData?.priority || '',
    escalationDetails: {
      isEscalated: initialData?.escalationDetails?.isEscalated || false,
      reason: initialData?.escalationDetails?.reason || ''
    }
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        customerName: initialData.customer,
        email: initialData.email || '',
        subject: initialData.name,
        description: initialData.description,
        attachments: initialData.attachments || [],
        assignedTo: initialData.assignedTo,
        status: initialData.status,
        priority: initialData.priority || '',
        escalationDetails: {
          isEscalated: initialData.escalationDetails?.isEscalated || false,
          reason: initialData.escalationDetails?.reason || ''
        }

      });
    }
  }, [initialData]);


  if (!isOpen) return null;


  const assignees = [
    { id: 'select', label: 'Select' },
    { id: 'john_smith', label: 'John Smith' },
    { id: 'sarah_parker', label: 'Sarah Parker' },
    { id: 'mike_johnson', label: 'Mike Johnson' },
    { id: 'emily_brown', label: 'Emily Brown' },
    { id: 'david_wilson', label: 'David Wilson' }
  ];

  const statuses = [
    { id: 'open', label: 'Open' },
    { id: 'assigned', label: 'Assigned' },
    { id: 'inprogress', label: 'Inprogress' },
    { id: 'resolved', label: 'Resolved' },
    { id: 'closed', label: 'Closed' }
  ];

  const priorities = [
    { id: 'high', label: 'High' },
    { id: 'medium', label: 'Medium' },
    { id: 'low', label: 'Low' }
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onClose();
    onAfterClose?.();
  };

  const handleCancel = () => {
    onClose();
    onAfterClose?.();
  };

  const handleDeleteAttachment = (index: number, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const newAttachments = [...formData.attachments];
    newAttachments.splice(index, 1);
    setFormData({ ...formData, attachments: newAttachments });
  };




  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <BorderGradient className='w-full max-w-2xl rounded-lg  flex flex-col'>
        <div className="bg-[#1A1A1A] w-full max-w-2xl rounded-lg flex flex-col" style={{ maxHeight: 'calc(90vh - 100px)' }}>
          <div className="flex justify-between items-center p-6 border-b border-white/[0.08]">
            <h2 className="text-xl text-white">{mode === 'create' ? 'Add Ticket' : 'Edit Ticket'}</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <FiX size={24} />
            </button>
          </div>

          <div className="p-6 overflow-y-auto flex-1">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm text-gray-400 mb-2">Customer Name</label>
                  <input
                    type="text"
                    value={formData.customerName}
                    onChange={(e) => setFormData({ ...formData, customerName: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-400 mb-2">Email</label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-2">Ticket subject</label>
                <input
                  type="text"
                  value={formData.subject}
                  onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                  className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-2">Description</label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white h-32"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-2">Attachments</label>
                <DropZone
                  onFilesAdded={(files) => setFormData({ ...formData, attachments: files })}
                />
              </div>


              {
                mode === 'edit' && formData.attachments.length > 0 && <div>
                  <label className="block text-sm text-gray-400 mb-2">Attachments</label>
                  <div className="flex-wrap gap-2">
                    {formData.attachments.map((file, index) => (
                      <div
                        key={index}
                        className="bg-[#2A2A2A] mb-2 border border-white/[0.08] rounded-md px-3 py-2 text-white flex items-center justify-between"
                      >
                        <span>{file.name}</span>
                        <div className="flex items-center gap-2">
                          <button
                            className="p-1.5 hover:bg-white/[0.08] rounded-lg text-gray-400 hover:text-white"
                            onClick={() => window.open(URL.createObjectURL(file), '_blank')}
                          >
                            <FiEye size={16} />
                          </button>
                          <button
                            className="p-1.5 hover:bg-white/[0.08] rounded-lg text-red-400 hover:text-red-300"
                            onClick={(e) => handleDeleteAttachment(index, e)}
                          >
                            <FiTrash2 size={16} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              }


              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm text-gray-400 mb-2">Assigned to</label>
                  <select
                    value={formData.assignedTo}
                    onChange={(e) => setFormData({ ...formData, assignedTo: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white"
                  >
                    {assignees.map(assignee => (
                      <option key={assignee.id} value={assignee.id}>{assignee.label}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-2">Status</label>
                  <select
                    value={formData.status}
                    onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white"
                  >
                    {statuses.map(status => (
                      <option key={status.id} value={status.id}>{status.label}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-2">Priority</label>
                  <select
                    value={formData.priority}
                    onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
                    className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white"
                  >
                    {priorities.map(priority => (
                      <option key={priority.id} value={priority.id}>{priority.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              {mode === 'edit' &&
                <div className="bg-[#2A2A2A]/50 p-4 rounded-lg">
                  <div className="mb-4">
                    <label className="flex items-center gap-2 text-sm text-gray-400">
                      <input

                        type="checkbox"
                        checked={formData.escalationDetails.isEscalated}
                        onChange={(e) => setFormData({
                          ...formData,
                          escalationDetails: {
                            ...formData.escalationDetails,
                            isEscalated: e.target.checked
                          }
                        })}
                        className="rounded border-gray-400"
                      />
                      Escalate to super admin
                    </label>
                  </div>

                  {formData.escalationDetails.isEscalated && mode === 'edit' && (
                    <div>
                      <label className="block text-sm text-gray-400 mb-2">Reason for escalation</label>
                      <textarea
                        value={formData.escalationDetails.reason}
                        onChange={(e) => setFormData({
                          ...formData,
                          escalationDetails: {
                            ...formData.escalationDetails,
                            reason: e.target.value
                          }
                        })}
                        className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-md px-3 py-2 text-white h-32"
                      />
                    </div>
                  )}
                </div>
              }
              <div className="flex justify-end gap-3 pt-4">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 text-gray-400 hover:text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#2A2A2A] hover:bg-[#3A3A3A] text-white rounded-lg"
                >
                  {mode === 'create' ? 'Save' : 'Update'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </BorderGradient>
    </div>
  );
} 