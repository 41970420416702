import { useState, useMemo } from 'react';
import TicketsHeader from 'components/tickets/TicketsHeader';
import TicketsFilters from 'components/tickets/TicketsFilters';
import TicketsTable from 'components/tickets/TicketsTable';
import { ticketsData } from '@/lib/constants';

export default function Tickets() {
  const [selectedTime, setSelectedTime] = useState('all');
  const [filters, setFilters] = useState({
    status: '',
    customer: '',
    assignedTo: '',
    startDate: null,
    endDate: null,
    statuses: [] as string[],
    search: ''
  });

  const tickets = ticketsData;
  // Filter tickets based on all filters
  const filteredTickets = useMemo(() => {
    return tickets.filter(ticket => {
      // Status filters
      if (filters.statuses.length > 0 && !filters.statuses.includes(ticket.status.toLowerCase())) {
        return false;
      }

      // Customer filter
      if (filters.customer && ticket.customer !== filters.customer) {
        return false;
      }

      // Assignee filter
      if (filters.assignedTo && filters.assignedTo !== 'Select' && ticket.assignedTo !== filters.assignedTo) {
        return false;
      }

      // Date range filter
      if (filters.startDate && filters.endDate) {
        // const ticketDate = new Date(ticket.addedOn);
        // if (ticketDate < filters.startDate || ticketDate > filters.endDate) {
        //   return false;
        // }
      }

      // Search filter
      if (filters.search) {
        const searchLower = filters.search.toLowerCase();
        return (
          ticket.id.toLowerCase().includes(searchLower) ||
          ticket.customer.toLowerCase().includes(searchLower) ||
          ticket.name.toLowerCase().includes(searchLower) ||
          ticket.description.toLowerCase().includes(searchLower)
        );
      }

      return true;
    });
  }, [tickets, filters]);

  return (
    <div className="px-6 pb-3 overflow-hidden overflow-y-auto h-full">
      <div className="pt-6 mb-6">
        <TicketsHeader 
          selectedTime={selectedTime} 
          setSelectedTime={setSelectedTime} 
        />
      </div>
      
      <TicketsFilters 
        filters={filters}
        setFilters={setFilters}
      />
      
      <TicketsTable tickets={filteredTickets} />
    </div>
  );
} 