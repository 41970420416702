import Layout from "components/layout";
// import Auth from "pages/auth";
// import Login from "pages/auth/Login";
// import Register from "pages/auth/Register";
// import ForgetPassword from "pages/auth/ForgetPassword";
import Dashboard from "pages/Dashboard/dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthGuard } from "utilities/AuthGuard";
import "./App.css";
import UsersContainer from "pages/users/UsersContainer";
import DigitalTwinContainer from "pages/DigitalTwinList/digitaltwinContainer";
import OrganizationAndUsersContainer from "pages/organization-and-users/OrganizationAndUsers";
import Logs from "pages/logs/Logs";
import Roles from "pages/roles/page";

import ActivityDetails from 'components/logs/ActivityDetails';
import ReportedActivity from 'components/logs/ReportedActivity';
import Tickets from "pages/tickets/Tickets";
// import UnityWP from "pages/waterplant";

function App() {
  return (
    <Routes>
      {/* <Route path="/auth">
        <Route element={<Auth />}>
          <Route index element={<Navigate to="/auth/login" />} />
          <Route path="login" element={<Login />}></Route>
          <Route path="register" element={<Register />}></Route>
          <Route path="forget-password" element={<ForgetPassword />}></Route>
        </Route>
      </Route> */}
      <Route element={<AuthGuard />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<UsersContainer />} />
          <Route path="/digital-twins" element={<DigitalTwinContainer />}></Route>
          <Route path="/organization-and-users" element={<OrganizationAndUsersContainer />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/logs/activity/:id" element={<ActivityDetails />} />
          <Route path="/logs/activity/reported" element={<ReportedActivity />} />
            <Route path="/tickets" element={<Tickets />} />
          <Route path="/roles" element={<Roles />} />
        </Route>
        {/* <Route path="/3d-dashboard" element={<UnityContainer />} /> */}
      </Route>
      <Route path="*" element={<p>Not Found</p>} />
    </Routes>
  );
}

export default App;
