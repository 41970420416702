import { useState } from 'react';
import { StatWidget } from 'components/shared/StatWidget';

export default function RolesHeader() {
    const [selectedTime, setSelectedTime] = useState('all');
    
    const stats = [
        { id: 'total_roles', label: 'Total Roles', value: '8', change: '+2.5%' },
        { id: 'active_roles', label: 'Active Roles', value: '6', change: '+1.2%' },
        { id: 'inactive_roles', label: 'Inactive Roles', value: '2', change: '-0.8%' },
        { id: 'total_permissions', label: 'Total Permissions', value: '24', change: '+3.1%' }
    ];

    const handleStatUpdate = (id: string, newData: any) => {
        // Handle stat updates
    };

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {stats.map(stat => (
                    <StatWidget
                        key={stat.id}
                        label={stat.label}
                        value={stat.value}
                        change={stat.change}
                        onUpdate={(newData) => handleStatUpdate(stat.id, newData)}
                    />
                ))}
            </div>
        </div>
    );
} 