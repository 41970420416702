export const usersMockData = [
    {
      id: 'PH34',
      name: 'Joy',
      userType: 'General Admin',
      status: 'Inhouse',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: 'NA',
      dueDate: 'NA',
      paymentStatus: 'NA',
      paymentMethod: 'NA',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 3
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Process Optimization',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
        
      ]
    },
    {
      id: 'PH76',
      name: 'Daniel',
      userType: 'Developer',
      status: 'Inhouse',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: 'NA',
      dueDate: 'NA',
      paymentStatus: 'NA',
      paymentMethod: 'NA',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 6
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Process Optimization',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
        {
          name: 'Cost Analysis',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Performance Metrics',
          role: 'User',
          owner: 'Jane Smith',
          isOwner: false
        },
        {
          name: 'Workflow Automation',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        }
      ]
    },
    {
      id: 'PH45',
      name: 'John',
      userType: 'Designer',
      status: 'Inhouse',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: 'NA',
      paymentStatus: 'NA',
      paymentMethod: 'NA',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 2
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
      
      ]
    },
    {
      id: 'PH54',
      name: 'Ann',
      userType: 'Subscriber',
      status: 'Expired',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: '04/05/2024',
      paymentStatus: 'Pending',
      paymentMethod: 'Credit card',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Operational Excellence',
          projectCount: 3
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
        
        {
          name: 'Performance Metrics',
          role: 'User',
          owner: 'Jane Smith',
          isOwner: false
        }
      ]
    },
    {
      id: 'PH66',
      name: 'Dany',
      userType: 'Subscriber',
      status: 'Active',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: '02/06/2024',
      paymentStatus: 'Paid',
      paymentMethod: 'Credit card',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 3
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Process Optimization',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
       
      ]
    },
    {
      id: 'PH43',
      name: 'Joy',
      userType: 'Trial',
      status: 'Active',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: '19/01/2025',
      paymentStatus: 'Paid',
      paymentMethod: 'Credit card',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 1
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
       
      ]
    }
  ];


  
export const demoOrganizationsMockData = [
    {
      id: 1,
      name: "Acme Corporation",
      desc: "This is a description for Acme Corporation",
      subscriberCount: 15,
      createdBy: "John Doe",
      createdDate: "12/03/2024",
      product: "Process Monitoring",
      projectCount: 7,
      users: [
        {
          id: 'PH321',
          name: 'Donna May',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          organization: 'Acme Corporation',
          phoneNumber: '+1234567890',
          email: 'donna@example.com',
          role: 'admin',
          projects: [
            {
              name: 'Project1',
              role: 'Admin',
              owner: 'Donna May',
              isOwner: true
            },
            {
              name: 'Project2',
              role: 'User',
              owner: 'John Doe',
              isOwner: false
            },
            {
              name: 'Project3',
              role: 'Admin',
              owner: 'Donna May',
              isOwner: true
            },
            {
              name: 'Project4',
              role: 'User',
              owner: 'Jane Smith',
              isOwner: false
            }
          ]
        },
        {
          id: 'PH322',
          name: 'Stephen Ogden',
          subscriptionType: 'Standard',
          product: 'Operational Excellence',
          projectCount: 3,
          projects: ['Project1', 'Project2'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567891',
          email: 'stephen@example.com',
          role: 'user'
        },
        {
          id: 'PH323',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',

          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
        {
          id: 'PH324',
          name: 'Jane Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',

          phoneNumber: '+1234567892',
          email: 'jane@example.com',
          role: 'user'
        },
        {
          id: 'PH325',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',

          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
        
        {
          id: 'PH326',
          name: 'Donna May',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',

          phoneNumber: '+1234567890',
          email: 'donna@example.com',
          role: 'admin'
        },
        {
          id: 'PH327',
          name: 'Stephen Ogden',
          subscriptionType: 'Standard',
          product: 'Operational Excellence',
          projectCount: 3,
          projects: ['Project1', 'Project2'],

          organization: 'Acme Corporation',
          phoneNumber: '+1234567891',
          email: 'stephen@example.com',
          role: 'user'
        },
        {
          id: 'PH328',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],

          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
        {
          id: 'PH329',
          name: 'Jane Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],

          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'jane@example.com',
          role: 'user'
        },
        {
          id: 'PH330',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],

          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
  
      ],
    },
    {
      id: 2,
      name: "TechStart Solutions",
      desc: "This is a description for TechStart Solutions",
      subscriberCount: 2,
      createdBy: "Jane Smith",
      createdDate: "15/03/2024",
      product: "Process Monitoring",
      projectCount: 4,
      users: [
        {
            id: 'PH321',
            name: 'Donna May',
            subscriptionType: 'Basic',
            product: 'Operational excellence',
            projectCount: 4,
            organization: 'Acme Corporation',
            phoneNumber: '+1234567890',
            email: 'donna@example.com',
            role: 'admin',
            projects: [
              {
                name: 'Project1',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project2',
                role: 'User',
                owner: 'John Doe',
                isOwner: false
              },
              {
                name: 'Project3',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project4',
                role: 'User',
                owner: 'Jane Smith',
                isOwner: false
              }
            ]
          },
          {
            id: 'PH323',
            name: 'Stephen Ogden',
            subscriptionType: 'Standard',
            product: 'Operational Excellence',
            projectCount: 3,
            projects: ['Project1', 'Project2'],
            organization: 'Acme Corporation',
            phoneNumber: '+1234567891',
            email: 'stephen@example.com',
            role: 'user'
          },
      ],
    },
    {
      id: 3,
      name: "Global Innovations Ltd",
      desc: "This is a description for Global Innovations Ltd",
      subscriberCount: 4,
      createdBy: "Mike Johnson",
      createdDate: "18/03/2024",
      product: "Process Monitoring",
      projectCount: 3,
      users: [
        {
            id: 'PH321',
            name: 'Donna May',
            subscriptionType: 'Basic',
            product: 'Operational excellence',
            projectCount: 4,
            organization: 'Acme Corporation',
            phoneNumber: '+1234567890',
            email: 'donna@example.com',
            role: 'admin',
            projects: [
              {
                name: 'Project1',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project2',
                role: 'User',
                owner: 'John Doe',
                isOwner: false
              },
              {
                name: 'Project3',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project4',
                role: 'User',
                owner: 'Jane Smith',
                isOwner: false
              }
            ]
          },
          {
            id: 'PH323',
            name: 'Stephen Ogden',
            subscriptionType: 'Standard',
            product: 'Operational Excellence',
            projectCount: 3,
            projects: ['Project1', 'Project2'],
            organization: 'Acme Corporation',
            phoneNumber: '+1234567891',
            email: 'stephen@example.com',
            role: 'user'
          },
          {
            id: 'PH321',
            name: 'Donna May',
            subscriptionType: 'Basic',
            product: 'Operational excellence',
            projectCount: 4,
            organization: 'Acme Corporation',
            phoneNumber: '+1234567890',
            email: 'donna@example.com',
            role: 'admin',
            projects: [
              {
                name: 'Project1',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project2',
                role: 'User',
                owner: 'John Doe',
                isOwner: false
              },
              {
                name: 'Project3',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project4',
                role: 'User',
                owner: 'Jane Smith',
                isOwner: false
              }
            ]
          },
          {
            id: 'PH323',
            name: 'Stephen Ogden',
            subscriptionType: 'Standard',
            product: 'Operational Excellence',
            projectCount: 3,
            projects: ['Project1', 'Project2'],
            organization: 'Acme Corporation',
            phoneNumber: '+1234567891',
            email: 'stephen@example.com',
            role: 'user'
          },
      ],
    },
  ];

export const activityLogsMockData = [
  {
    timestamp: '2024-03-20 14:30:45',
    user: 'John Doe',
    userType: 'Admin',
    activity: 'Login',
    description: 'Here is the description for User logged in successfully',
    status: 'Success'
  },
  {
    timestamp: '2024-03-20 14:25:30',
    user: 'Jane Smith',
    userType: 'User',
    activity: 'Create Project',
    description: 'Here is the description for Created new project "Analytics Dashboard"',
    status: 'Success'
  },
  {
    timestamp: '2024-03-20 14:20:15',
    user: 'Mike Johnson',
    userType: 'Manager',
    activity: 'Update User',
    description: 'Here is the description for Updated user permissions',
    status: 'Failed'
  },
  {
    timestamp: '2024-03-20 14:15:00',
    user: 'Sarah Wilson',
    userType: 'User',
    activity: 'Delete Project',
    description: 'Here is the description for Deleted project "Old Dashboard"',
    status: 'Success'
  },
  // Add more activity logs...
];

export const emailLogsMockData = [
  {
    date: '2024-03-20',
    emailType: 'Welcome Email',
    status: 'Sent',
    sendTo: 'john@example.com'
  },
  {
    date: '2024-03-20',
    emailType: 'Password Reset',
    status: 'Failed',
    sendTo: 'jane@example.com'
  },
  {
    date: '2024-03-20',
    emailType: 'Verification',
    status: 'Sent',
    sendTo: 'mike@example.com'
  },
  {
    date: '2024-03-20',
    emailType: 'Newsletter',
    status: 'Pending',
    sendTo: 'sarah@example.com'
  },
  // Add more email logs...
];

export const auditTrailsMockData = [
  {
    date: '2024-03-20',
    user: 'John Doe',
    userType: 'Admin',
    change: 'Role Update',
    description: 'Here is the description for Changed user role from User to Admin'
  },
  {
    date: '2024-03-20',
    user: 'Jane Smith',
    userType: 'Manager',
    change: 'Permission Change',
    description: 'Here is the description for Added project management permissions'
  },
  {
    date: '2024-03-20',
    user: 'Mike Johnson',
    userType: 'User',
    change: 'Settings Update',
    description: 'Here is the description for Updated organization settings'
  },
  {
    date: '2024-03-20',
    user: 'Sarah Wilson',
    userType: 'Guest',
    change: 'Data Delete',
    description: 'Here is the description for Removed old project data'
  },
  // Add more audit trails...
];

export interface Ticket {
  id: string;
  status: string;
  customer: string;
  name: string;
  description: string;
  addedOn: string;

  assignedTo: string;
  lastUpdated: string;
  email?: string;
  priority?: string;
  escalationDetails?: {
      isEscalated: boolean;
      reason?: string;
      date?: string;
  };
  attachments?: File[];
}


export const ticketsData: Ticket[] = [
  {
      id: '#0001',
      status: 'Open',
      customer: 'John Doe',
      email: 'johndoe@gmail.com',
      name: 'Lorem ipsum',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      addedOn: '5/02/2024',
      assignedTo: 'john_smith',
      lastUpdated: '12/03/2025',
      priority: 'High',
      escalationDetails: {
          isEscalated: true,
          date: '5/02/2024',
          reason: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
      },
      attachments: [
          new File([""], "document1.pdf", { type: "application/pdf" }),
          new File([""], "image1.jpg", { type: "image/jpeg" }),
          new File([""], "spreadsheet1.xlsx", { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      ]
  },
  {
      id: '#0123',
      status: 'Open',
      customer: 'John Doe',
      name: 'Lorem ipsum',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore..',
      addedOn: '12/06/2024',
      assignedTo: '',
      lastUpdated: '12/03/2025'
  },
  {
      id: '#0003',
      status: 'Assigned',
      customer: 'Donna May',
      name: 'Lorem ipsum',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore..',
      addedOn: '12/03/2025',
      assignedTo: '',
      lastUpdated: '12/03/2025'
  },
  {
      id: '#0004',
      status: 'Inprogress',
      customer: 'James Vance',
      name: 'Lorem ipsum',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore..',
      addedOn: '04/05/2024',
      assignedTo: '',
      lastUpdated: '12/03/2025'
  },
  {
      id: '#0005',
      status: 'Resolved',
      customer: 'Stephen Ogden',
      name: 'Lorem ipsum',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore..',
      addedOn: '12/03/2025',
      assignedTo: '',
      lastUpdated: '12/03/2025'
  },
  {
      id: '#0090',
      status: 'Resolved',
      customer: 'Stewart Skinner',
      name: 'Lorem ipsum',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore..',
      addedOn: '02/06/2024',
      assignedTo: '',
      lastUpdated: '12/03/2025'
  },
  {
      id: '#0012',
      status: 'Closed',
      customer: 'Mark Francis',
      name: 'Lorem ipsum',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore..',
      addedOn: '19/01/2025',
      assignedTo: '',
      lastUpdated: '12/03/2025'
  },
  {
      id: '#0002',
      status: 'Inprogress',
      customer: 'Jane Smith',
      email: 'janesmith@gmail.com',
      name: 'Technical Issue',
      description: 'Here is the description for Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      addedOn: '6/02/2024',
      assignedTo: 'sarah_parker',
      lastUpdated: '13/03/2025',
      priority: 'Medium',
      escalationDetails: {
          isEscalated: false
      },
      attachments: [
          new File([""], "screenshot.png", { type: "image/png" })
      ]
  }
];

export const roles = [
  {
      id: '1',
      name: 'Super Admin',
      description: 'Here is the description for Manages admins, billing admins, support agents, developers, and...',
      isActive: true,
      permissions: ['all'],
      createdAt: '2024-02-01'
  },
  {
      id: '2',
      name: 'Billing Admin',
      description: 'Here is the description for Handles payment processing, subscription management, and billing issues...',
      isActive: true,
      permissions: ['billing.manage', 'billing.view'],
      createdAt: '2024-02-01'
  },
  {
      id: '3',
      name: 'Customer',
      description: 'Here is the description for Manages their accounts and interacts with Support Agents for help...',
      isActive: true,
      permissions: ['account.manage', 'tickets.create'],
      createdAt: '2024-02-01'
  },
  {
      id: '4',
      name: 'Trial User',
      description: 'Here is the description for Manages their accounts and interacts with Support Agents for help.',
      isActive: true,
      permissions: ['account.view', 'tickets.create'],
      createdAt: '2024-02-01'
  },
  {
      id: '5',
      name: 'Support Agent',
      description: 'Here is the description for Provides customer support by managing tickets and interacting with...',
      isActive: true,
      permissions: ['tickets.manage', 'tickets.respond'],
      createdAt: '2024-02-01'
  },
  {
      id: '6',
      name: 'Developer',
      description: 'Here is the description for Focuses on API integration and platform customization...',
      isActive: true,
      permissions: ['api.access', 'api.manage'],
      createdAt: '2024-02-01'
  }
];



export interface Permission {
  id: string;
  name: string;
  isEnabled: boolean;
  functionalities: {
      id: string;
      name: string;
      description: string;
      isEnabled: boolean;
  }[];
}
export const permissions: Permission[] = [
  {
      id: '1',
      name: 'User Management',
      isEnabled: true,
      functionalities: [
          { id: '1', name: 'View users', description: 'Here is the description for View users', isEnabled: true },
          { id: '2', name: 'Create users', description: 'Here is the description for Create users', isEnabled: true },
          { id: '3', name: 'Edit users', description: 'Here is the description for Edit users', isEnabled: true },
          { id: '4', name: 'Delete users', description: 'Here is the description for Delete users', isEnabled: true },
      ]
  },
  {
      id: '2',
      name: 'Plan',
      isEnabled: true,
      functionalities: [
          { id: '5', name: 'View Plan', description: 'Here is the description for View Plan', isEnabled: true },
          { id: '6', name: 'Create Plan', description: 'Here is the description for Create Plan', isEnabled: false },
          { id: '7', name: 'Edit Plan', description: 'Here is the description for Edit Plan', isEnabled: false },
          { id: '8', name: 'Delete Plan', description: 'Here is the description for Delete Plan', isEnabled: false },
          { id: '9', name: 'Archive Plan', description: 'Here is the description for Archive Plan', isEnabled: false },
          { id: '10', name: 'Cancel Plan', description: 'Here is the description for Cancel Plan', isEnabled: false },
      ]
  },
  {
      id: '3',
      name: 'Subscription',
      isEnabled: true,
      functionalities: [
          { id: '11', name: 'View Subscription', description: 'Here is the description for View Subscription', isEnabled: true },
          { id: '12', name: 'Create Subscription', description: 'Here is the description for Create Subscription', isEnabled: false },
          { id: '13', name: 'Edit Subscription', description: 'Here is the description for Edit Subscription', isEnabled: false },
          { id: '14', name: 'Cancel Subscription', description: 'Here is the description for Cancel Subscription', isEnabled: false },
      ]
  },
  {
      id: '4',
      name: 'Billing',
      isEnabled: true,
      functionalities: [
          { id: '15', name: 'Handle payments', description: 'Here is the description for Handle payments',  isEnabled: true },
          { id: '16', name: 'Manage billing', description: 'Here is the description for Manage billing', isEnabled: true },
          { id: '17', name: 'Retry failed payments', description: 'Here is the description for Retry failed payments', isEnabled: true },
          { id: '18', name: 'View Billing History', description: 'Here is the description for View Billing History', isEnabled: true },
          { id: '19', name: 'Manage Upgrades/Downgrades/Cancellations', description: 'Here is the description for Manage Upgrades/Downgrades/Cancellations', isEnabled: true },
          { id: '20', name: 'Manage refund requests', description: 'Here is the description for Manage refund requests', isEnabled: true },
          { id: '21', name: 'Approve refund requests', description: 'Here is the description for Approve refund requests', isEnabled: true },
          { id: '22', name: 'Adjust accounts with credits', description: 'Here is the description for Adjust accounts with credits', isEnabled: true },
      ]
  },
  {
      id: '5',
      name: 'Support Management',
      isEnabled: true,
      functionalities: [
          { id: '23', name: 'Create support ticket', description: 'Here is the description for Create support ticket', isEnabled: true },
          { id: '24', name: 'View support ticket', description: 'Here is the description for View support ticket', isEnabled: true },
          { id: '25', name: 'Manage support ticket', description: 'Here is the description for Manage support ticket', isEnabled: true },
          { id: '26', name: 'Access Knowledge Base', description: 'Here is the description for Access Knowledge Base', isEnabled: true },
          { id: '27', name: 'Update Knowledge Base', description: 'Here is the description for Update Knowledge Base', isEnabled: true },
          { id: '28', name: 'Manage refund requests', description: 'Here is the description for Manage refund requests', isEnabled: true },
          { id: '29', name: 'Approve refund requests', description: 'Here is the description for Approve refund requests', isEnabled: true },
          { id: '30', name: 'Adjust accounts with credits', description: 'Here is the description for Adjust accounts with credits', isEnabled: true },
      ]
  },
  {
      id: '6',
      name: 'Dashboard',
      isEnabled: true,
      functionalities: [
          { id: '31', name: 'View', description: 'Here is the description for View', isEnabled: true },
      ]
  },
  {
      id: '7',
      name: 'System settings',
      isEnabled: true,
      functionalities: [
          { id: '32', name: 'Manage integrations', description: 'Here is the description for Manage integrations', isEnabled: true },
          { id: '33', name: 'API Access', description: 'Here is the description for API Access', isEnabled: true },
          { id: '34', name: 'Access to API documentation', description: 'Here is the description for Access to API documentation', isEnabled: true },
      ]
  },
  {
      id: '8',
      name: 'Notification',
      isEnabled: true,
      functionalities: [
          { id: '35', name: 'Users upgrade to higher plans or downgrade', description: 'Here is the description for Users upgrade to higher plans or downgrade', isEnabled: true },
          { id: '36', name: "User's subscription is about to expire", description: "User's subscription is about to expire", isEnabled: true },
          { id: '37', name: 'System health, performance issues, or security threats', description: 'Here is the description for System health, performance issues, or security threats', isEnabled: true },
          { id: '38', name: 'For unusual activity in the platform', description: 'Here is the description for For unusual activity in the platform', isEnabled: true },
          { id: '39', name: 'Send payment follow-up messages to customers', description: 'Here is the description for Send payment follow-up messages to customers', isEnabled: true },
          { id: '40', name: 'For failed payment attempts', description: 'Here is the description for For failed payment attempts', isEnabled: true },
          { id: '41', name: 'When the trial period is about to end and recommend', description: 'Here is the description for When the trial period is about to end and recommend', isEnabled: true },
          { id: '42', name: 'Refund approvals, payment retries, and subscription downgrades', description: 'Here is the description for Refund approvals, payment retries, and subscription downgrades', isEnabled: true },
      ]
  },
  {
      id: '9',
      name: 'Security',
      isEnabled: true,
      functionalities: [
          { id: '43', name: 'Setup and enforce security measures', description: 'Here is the description for Setup and enforce security measures', isEnabled: true },
          { id: '44', name: 'Access and review logs', description: 'Here is the description for Access and review logs', isEnabled: true },
      ]
  }
];