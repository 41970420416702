import * as Tabs from '@radix-ui/react-tabs';
import RolesTable from './RolesTable';
import { useState, useMemo } from 'react';
import { roles } from '@/lib/constants';
import RoleModal from './RoleModal';
import PermissionsContent from './PermissionsContent';
import SearchBar from 'components/shared/SearchBar';
import { FiPlus } from 'react-icons/fi';

interface Role {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    permissions: string[];
    createdAt: string;
}

export default function RolesTabs() {
    const [rolesData, setRolesData] = useState<Role[]>(roles);
    const [activeTab, setActiveTab] = useState('roles');
    const [editingRole, setEditingRole] = useState<Role | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [mode, setMode] = useState<'create' | 'edit'>('edit');
    // Add filtered roles using useMemo
    const filteredRoles = useMemo(() => {
        if (!searchTerm) return rolesData;

        const searchLower = searchTerm.toLowerCase();
        return rolesData.filter(role =>
            role.name.toLowerCase().includes(searchLower) ||
            role.description.toLowerCase().includes(searchLower)
        );
    }, [rolesData, searchTerm]);

    const handleEdit = (role: Role) => {
        setMode('edit');
        setEditingRole({
            id: role.id,
            name: role.name,
            description: role.description,
            isActive: role.isActive,
            permissions: role.permissions,
            createdAt: role.createdAt
        });
        setIsEditModalOpen(true);
    };

    const handleUpdateRole = (updatedRole: Role) => {
        setRolesData(prevRoles =>
            prevRoles.map(role =>
                role.id === updatedRole.id ? updatedRole : role
            )
        );
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <Tabs.List>
                    <div className="flex">
                        <Tabs.Trigger
                            value="roles"
                            className={`px-6 py-2.5 text-sm transition-colors relative flex-1 max-w-[100px] ${activeTab === 'roles'
                                    ? 'bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] text-white rounded-lg'
                                    : 'bg-[#2A2A2A] text-gray-400 rounded-lg'
                                }`}
                            onClick={() => setActiveTab('roles')}
                        >
                            Roles
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="permissions"
                            className={`px-6 py-2.5 text-sm transition-colors relative flex-1 max-w-[120px] ${activeTab === 'permissions'
                                    ? 'bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] text-white rounded-lg'
                                    : 'bg-[#2A2A2A] text-gray-400 rounded-lg'
                                }`}
                            onClick={() => setActiveTab('permissions')}
                        >
                            Permission
                        </Tabs.Trigger>
                    </div>
                </Tabs.List>


            </div>

            <div className='mt-4 flex justify-between items-center'>
                {activeTab === 'roles' && (
                    <SearchBar
                        value={searchTerm}
                        onChange={setSearchTerm}
                        placeholder="Search roles..."
                    />
                )}

                {activeTab === 'roles' && (
                    <button
                        onClick={() => {
                            setMode('create');
                            setIsEditModalOpen(true);
                        }}
                        className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-4 py-2 text-white"
                    >
                        <FiPlus /> Add Role
                    </button>
                )}
            </div>

            <div className="mt-4">
                <Tabs.Content value="roles" className="outline-none">
                    <RolesTable
                        roles={filteredRoles}
                        onUpdateRole={handleUpdateRole}
                        onEdit={handleEdit}
                    />
                </Tabs.Content>
                <Tabs.Content value="permissions" className="outline-none">
                    <PermissionsContent />
                </Tabs.Content>
            </div>

            <RoleModal
                isOpen={isEditModalOpen}
                onClose={() => {
                    setIsEditModalOpen(false);
                    setEditingRole(null);
                    setMode('create');
                }}
                mode={mode}
                initialData={editingRole}
            />
        </div>
    );
} 