import * as Dialog from '@radix-ui/react-dialog';
import { FiX } from 'react-icons/fi';
import Switch from 'components/shared/Switch';
import { useState, useEffect } from 'react';
import BorderGradient from 'components/shared/BorderGradient/BorderGradient';

interface RoleModalProps {
    isOpen: boolean;
    onClose: () => void;
    mode: 'create' | 'edit';
    initialData?: any;
}

export default function RoleModal({ isOpen, onClose, mode, initialData }: RoleModalProps) {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        isActive: false
    });

    useEffect(() => {
        if (initialData && mode === 'edit') {
            setFormData({
                name: initialData.name || '',
                description: initialData.description || '',
                isActive: initialData.isActive || false
            });
        }
    }, [initialData, mode]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (mode === 'edit') {
            console.log('Editing role:', formData);
        } else {
            console.log('Creating role:', formData);
        }
        onClose();
    };

    const handleClose = () => {
        setFormData({
            name: '',
            description: '',
            isActive: false
        });
        onClose();
    };

    return (
        <Dialog.Root open={isOpen} onOpenChange={handleClose}>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/50" />
                <Dialog.Content className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-lg">
                    <BorderGradient className='w-full max-w-2xl rounded-lg  flex flex-col'>
                    <div className="bg-[#1A1A1A] rounded-lg shadow-lg ">
                        <div className="flex items-center justify-between p-6 border-b border-white/[0.08]">
                            <Dialog.Title className="text-lg font-semibold text-white">
                                {mode === 'create' ? 'Add role' : 'Edit role'}
                            </Dialog.Title>
                            <button
                                onClick={onClose}
                                className="text-gray-400 hover:text-white transition-colors"
                            >
                                <FiX size={24} />
                            </button>
                        </div>

                        <form onSubmit={handleSubmit} className="p-6">
                            <div className="space-y-6">
                                <div className="flex items-center justify-between">
                                    <label className="text-sm text-gray-400">Role Name</label>
                                    <div className="flex items-center gap-2">
                                        <span className="text-sm text-gray-400">Set as active</span>
                                        <Switch
                                            checked={formData.isActive}
                                            onChange={() => setFormData(prev => ({ ...prev, isActive: !prev.isActive }))}
                                        />
                                    </div>
                                </div>

                                <input
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                    placeholder="Enter role name"
                                    className="w-full bg-[#2A2A2A] rounded-lg px-4 py-2.5 text-white border border-white/[0.08] focus:outline-none focus:border-white/[0.16]"
                                />

                                <div>
                                    <label className="block text-sm text-gray-400 mb-2">Description</label>
                                    <textarea
                                        value={formData.description}
                                        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                                        placeholder="Enter role description"
                                        rows={4}
                                        className="w-full bg-[#2A2A2A] rounded-lg px-4 py-2.5 text-white border border-white/[0.08] focus:outline-none focus:border-white/[0.16] resize-none"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end gap-3 mt-6">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-[#2A2A2A] text-white rounded-lg hover:bg-[#333333] transition-colors"
                                >
                                    {mode === 'create' ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                    </BorderGradient> 
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
} 