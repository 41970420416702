import { useState } from 'react';
import classNames from 'utilities/ClassNames';
import { StatWidget } from 'components/shared/StatWidget';

interface TimeFilterProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const TimeFilter = ({ label, isActive, onClick }: TimeFilterProps) => (
  <button
    onClick={onClick}
    className={classNames(
      'px-6 py-2 relative z-10 transition-colors duration-200 text-sm',
      isActive ? 'text-white' : 'text-gray-400 hover:text-gray-300'
    )}
  >
    {label}
  </button>
);

export default function TicketsHeader({ selectedTime, setSelectedTime }: { selectedTime: string, setSelectedTime: (time: string) => void }) {
  const timeFilters = [
    { id: 'all', label: 'All Time' },
    { id: '12m', label: '12 Months' },
    { id: '30d', label: '30 Days' },
    { id: '7d', label: '7 Days' },
    { id: '24h', label: '24 Hour' },
  ];

  const selectedIndex = timeFilters.findIndex(filter => filter.id === selectedTime);

  const [stats, setStats] = useState([
    { id: 1, label: "Total Tickets", value: "10", change: "+15%" },
    { id: 2, label: "Open tickets", value: "1", change: "+15%" },
    { id: 3, label: "Assigned tickets", value: "1", change: "0%" },
    { id: 4, label: "Inprogress", value: "4", change: "-10%" },
    { id: 5, label: "Onhold", value: "0", change: "0%" },
    { id: 6, label: "Waiting for customer", value: "0", change: "+8%" },
  ]);

  const handleStatUpdate = (id: number, newData: any) => {
    setStats(stats.map(stat => 
      stat.id === id ? { ...stat, ...newData } : stat
    ));
  };

  return (
    <div className="mb-8">
      <div className="mb-6">
        <div className="inline-flex bg-[#1A1A1A] rounded-lg relative border border-white/[0.08]">
          <div
            className="absolute h-full top-0 bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] rounded-lg transition-all duration-300 ease-out"
            style={{
              width: `${100 / timeFilters.length}%`,
              left: `${(selectedIndex * 100) / timeFilters.length}%`,
            }}
          />

          <div className="relative flex">
            {timeFilters.map((filter) => (
              <TimeFilter
                key={filter.id}
                label={filter.label}
                isActive={selectedTime === filter.id}
                onClick={() => setSelectedTime(filter.id)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6">
        {stats.map(stat => (
          <StatWidget
            key={stat.id}
            label={stat.label}
            value={stat.value}
            change={stat.change}
            onUpdate={(newData) => handleStatUpdate(stat.id, newData)}
          />
        ))}
      </div>
    </div>
  );
} 