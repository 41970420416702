import BorderGradient from 'components/shared/BorderGradient/BorderGradient';
import { FiX, FiEdit2, FiEye, FiDownload } from 'react-icons/fi';
import { useState, useEffect } from 'react';
import TicketModal from './TicketModal';

interface TicketDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    ticket: any;
    onEdit: (ticket: any) => void;
}

export default function TicketDetailsModal({ isOpen, onClose, ticket, onEdit }: TicketDetailsModalProps) {
    const [attachments, setAttachments] = useState<File[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(isOpen);

    useEffect(() => {
        setShowDetailsModal(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (ticket?.attachments) {
            setAttachments(ticket.attachments);
        }
    }, [ticket]);

    const handleEdit = () => {
        setShowDetailsModal(false);
        setShowEditModal(true);
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
        setShowDetailsModal(true);
    };

    if (!ticket) return null;

    return (
        <>
            {showDetailsModal && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <BorderGradient className='rounded-lg flex flex-col'>
                        <div className="bg-[#1A1A1A] w-full max-w-3xl rounded-lg flex flex-col" style={{ maxHeight: 'calc(90vh - 100px)' }}>
                            <div className="flex justify-between items-center p-6 border-b border-white/[0.08]">
                                <h2 className="text-xl text-white">Ticket Details</h2>
                                <div className="flex items-center gap-4">
                                    <button
                                        onClick={handleEdit}
                                        className="p-2 hover:bg-white/[0.08] rounded-lg text-gray-400 hover:text-white"
                                    >
                                        <FiEdit2 size={20} />
                                    </button>
                                    <button onClick={onClose} className="text-gray-400 hover:text-white">
                                        <FiX size={24} />
                                    </button>
                                </div>
                            </div>

                            <div className="p-6 space-y-6 overflow-y-auto">
                                <div className="bg-[#2A2A2A]/50 rounded-lg p-6">
                                    <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                                        <div>
                                            <label className="block text-sm text-gray-400 mb-1">Ticket ID</label>
                                            <p className="text-white">{ticket.id}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-400 mb-1">Customer Name</label>
                                            <p className="text-white">{ticket.customer}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-400 mb-1">Customer Email</label>
                                            <p className="text-white">{ticket.email}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-400 mb-1">Created Date</label>
                                            <p className="text-white">{ticket.addedOn}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-400 mb-1">Priority</label>
                                            <p className="text-white">{ticket.priority}</p>
                                        </div>
                                        <div>
                                            <label className="block text-sm text-gray-400 mb-1">Subject</label>
                                            <p className="text-white">{ticket.name}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-[#2A2A2A]/50 rounded-lg p-6">
                                    <label className="block text-sm text-gray-400 mb-2">Description</label>
                                    <p className="text-white whitespace-pre-wrap">{ticket.description}</p>
                                </div>

                                {attachments && attachments.length > 0 && (
                                    <div className="bg-[#2A2A2A]/50 rounded-lg p-6">
                                        <label className="block text-sm text-gray-400 mb-3">Attachments</label>
                                        <div className="space-y-2">
                                            {attachments.map((file: File, index: number) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center justify-between bg-[#2A2A2A] p-2 rounded-lg"
                                                >
                                                    <span className="text-gray-400">{file.name}</span>
                                                    <div className="flex items-center gap-2">
                                                        <button 
                                                            className="p-1.5 hover:bg-white/[0.08] rounded-lg text-gray-400 hover:text-white"
                                                            onClick={() => window.open(URL.createObjectURL(file), '_blank')}
                                                        >
                                                            <FiEye size={16} />
                                                        </button>
                                                        <button 
                                                            className="p-1.5 hover:bg-white/[0.08] rounded-lg text-gray-400 hover:text-white"
                                                            onClick={() => {
                                                                const url = URL.createObjectURL(file);
                                                                const a = document.createElement('a');
                                                                a.href = url;
                                                                a.download = file.name;
                                                                document.body.appendChild(a);
                                                                a.click();
                                                                document.body.removeChild(a);
                                                                URL.revokeObjectURL(url);
                                                            }}
                                                        >
                                                            <FiDownload size={16} />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {ticket.escalationDetails?.isEscalated && (
                                    <div className="bg-[#2A2A2A]/50 rounded-lg p-6">
                                        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                                            <div>
                                                <label className="block text-sm text-gray-400 mb-1">Escalated Date</label>
                                                <p className="text-white">{ticket.escalationDetails.date}</p>
                                            </div>
                                            <div>
                                                <label className="block text-sm text-gray-400 mb-1">Reason for escalation</label>
                                                <p className="text-white whitespace-pre-wrap">{ticket.escalationDetails.reason}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </BorderGradient>
                </div>
            )}

            <TicketModal
                isOpen={showEditModal}
                onClose={handleEditModalClose}
                initialData={ticket}
                mode="edit"
                onAfterClose={handleEditModalClose}
            />
        </>
    );
} 