import { useState } from 'react';
import RolesHeader from 'components/roles/RolesHeader';
import RolesTabs from 'components/roles/RolesTabs';
import * as Tabs from '@radix-ui/react-tabs';
import { FiPlus } from 'react-icons/fi';
import RoleModal from 'components/roles/RoleModal';

export default function Roles() {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('roles');

    return (
        <div className="px-6 pb-3 overflow-hidden overflow-y-auto h-full">
            <div className="pt-6 mb-6">
                <RolesHeader />
            </div>

            <Tabs.Root defaultValue="roles" onValueChange={setActiveTab}>
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold text-white">Role and Permission</h1>
                    
                </div>

                <RolesTabs />
            </Tabs.Root>

            <RoleModal
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                mode="create"
            />
        </div>
    );
} 