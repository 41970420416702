// import { getCount } from "api/alarm";
import BrainImg from "components/shared/BrainImg";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BsTags } from "react-icons/bs";
import { FiShield, FiUsers } from "react-icons/fi";
import { RxDashboard, RxGlobe, RxIdCard, RxStopwatch } from "react-icons/rx";
import { TbBrandUnity } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { GoOrganization } from "react-icons/go";
import { IoTicketSharp } from "react-icons/io5";

const links = [
  {
    id: 1,
    name: "Projects",
    to: "/dashboard",
    icon: <RxGlobe size={20} />,
  },
  // {
  //   id: 2,
  //   name: "List of Digital Twins",
  //   to: "/digital-twins",
  //   icon: <TbBrandUnity size={20} />,
  // },
  {
    id: 3,
    name: "Organizations and Users",
    to: "/organization-and-users",
    icon: <GoOrganization size={20} />,
  },
  {
    id: 4,
    name: "Tickets",
    to: "/tickets",
    icon: <IoTicketSharp size={20} />
  },
  {
    id: 5,
    name: "Logs",
    to: "/logs",
    icon: <RxStopwatch size={20} />,
  },
  {
    id: 6,
    name: "Roles",
    to: "/roles",
    icon: <FiShield size={20} />,
  },
  // {
  //   id: 3,
  //   name: "Tags",
  //   to: "/dashboard-configuration",
  //   icon: <BsTags size={20} />,
  // },

  // {
  //   id: 4,
  //   name: "Alarms",
  //   to: "/alarms",
  //   icon: <RxStopwatch size={20} />,
  // },
  // {
  //   id: 5,
  //   name: "Tickets",
  //   to: "/tickets",
  //   icon: <RxIdCard size={20} />,
  // },
  // {
  //   id: 6,
  //   name: "Users",
  //   to: "/users",
  //   icon: <FiUsers size={20} />,
  // },
];

export default function SideMenu({ closeMenu, showSideMenu }: any) {
  const [alarmCount, setAlarmCount] = useState();
  const slide = {
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
      },
      display: "flex",
    },
    exit: {
      opacity: 0,
      x: "-100%",
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  const appear = {
    enter: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
      display: "block",
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.1,
        delay: 0.3,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  // const getAlarmCount = async () => {
  //   const response: any = await getCount();
  //   if (response.status === 200) {
  //     setAlarmCount(response.data);
  //   }
  // };
  // useEffect(() => {
  //   getAlarmCount();
  // }, []);
  return (
    <motion.div
      initial="exit"
      animate={showSideMenu ? "enter" : "exit"}
      variants={appear}
      className="fixed z-40 inset-0 w-full bg-black/90"
      onClick={closeMenu}
    >
      <motion.div
        initial="exit"
        animate={showSideMenu ? "enter" : "exit"}
        variants={slide}
        className="absolute h-full left-0 top-0 w-[260px] z-20 bg-gray"
      >
        <div className="h-full w-full mt-20 pb-20 flex flex-col justify-between">
          <ul className="flex flex-col">
            {links.map((link: any) => (
              <NavLink
                key={link.id}
                to={link.to}
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                    : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
                }
              >
                {link.icon}
                <span>{link.name}</span>
                {link.name === "Alarms" && (
                  <div className="animate-pulse bg-rose-600 text-white bg-red rounded-md text-sm px-1 grid place-content-center">
                    {alarmCount}
                  </div>
                )}
              </NavLink>
            ))}
          </ul>
          <div className="grid place-content-center">
            <BrainImg />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
