import { useState, useEffect } from 'react';
import { FiChevronDown, FiChevronUp, FiCalendar, FiPlus, FiSearch } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TicketModal from './TicketModal';
import classNames from 'utilities/ClassNames';
import './TicketsFilters.css';

interface TicketsFiltersProps {
    filters: {
        status: string;
        customer: string;
        assignedTo: string;
        startDate: Date | null;
        endDate: Date | null;
        statuses: string[];
        search: string;
    };
    setFilters: (filters: any) => void;
}

export default function TicketsFilters({ filters, setFilters }: TicketsFiltersProps) {
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

    const statuses = [
        { id: 'open', label: 'Open' },
        { id: 'assigned', label: 'Assigned' },
        { id: 'inprogress', label: 'InProgress' },
        { id: 'resolved', label: 'Resolved' },
        { id: 'closed', label: 'Closed' }
    ];

    const customers = [
        { id: 'john_doe', label: 'John Doe' },
        { id: 'donna_may', label: 'Donna May' },
        { id: 'james_vance', label: 'James Vance' },
        { id: 'stephen_ogden', label: 'Stephen Ogden' },
        { id: 'stewart_skinner', label: 'Stewart Skinner' },
        { id: 'mark_francis', label: 'Mark Francis' }
    ];

    const assignees = [
        { id: 'select', label: 'Select' },
        { id: 'john_smith', label: 'John Smith' },
        { id: 'sarah_parker', label: 'Sarah Parker' },
        { id: 'mike_johnson', label: 'Mike Johnson' },
        { id: 'emily_brown', label: 'Emily Brown' },
        { id: 'david_wilson', label: 'David Wilson' }
    ];

    const handleDropdownClick = (dropdownName: string) => {
        setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
    };

    const handleOptionSelect = (dropdownName: string, value: string) => {
        setFilters((prev: any) => ({ ...prev, [dropdownName]: value }));
        setOpenDropdown(null);
    };

    const clearFilters = () => {
        setFilters({
            status: '',
            customer: '',
            assignedTo: '',
            startDate: null,
            endDate: null,
            statuses: [],
            search: ''
        });
        setSelectedStatuses([]);
        setSearchTerm('');
    };

    const handleStatusButtonClick = (statusId: string) => {
        setSelectedStatuses(prev => {
            if (prev.includes(statusId)) {
                return prev.filter(id => id !== statusId);
            } else {
                return [...prev, statusId];
            }
        });
    };

    useEffect(() => {
        setFilters((prev: any) => ({
            ...prev,
            statuses: selectedStatuses,
            search: searchTerm
        }));
    }, [selectedStatuses, searchTerm]);

    const StatusDropdown = () => (
        <div className="relative">
            <button
                onClick={() => handleDropdownClick('status')}
                className="flex items-center justify-between bg-[#2A2A2A] rounded-md px-3 py-2 text-gray-400 hover:bg-[#3A3A3A]"
                style={{
                    minWidth: '125px',
                    width: '125px',
                    maxWidth: '125px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'center',
                }}
            >
                <div className="flex items-center justify-center flex-1 gap-1">
                    {selectedStatuses.length > 0 ? (
                        <span className="flex items-center gap-1">
                            {`${selectedStatuses.length} selected`}
                        </span>
                    ) : (
                        'Status'
                    )}
                </div>
                {openDropdown === 'status' ? <FiChevronUp /> : <FiChevronDown />}
            </button>


            {openDropdown === 'status' && (
                <>
                    <div
                        className="fixed inset-0 z-10"
                        onClick={() => setOpenDropdown(null)}
                    />
                    <div className="status-dropdown">
                        {statuses.map(status => (
                            <label
                                key={status.id}
                                className="status-checkbox-label"
                            >
                                <div className="flex items-center gap-1 flex-1">
                                    <input
                                        type="checkbox"
                                        checked={selectedStatuses.includes(status.id)}
                                        onChange={() => handleStatusButtonClick(status.id)}
                                        className="status-checkbox"
                                    />
                                    <span className={`status-dot ${status.id}`} />
                                    <span className={`text-${status.id}`}>{status.label}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </>
            )}
        </div>
    );

    const Dropdown = ({ name, options, label }: { name: string; options: any[]; label: string }) => (
        <div className="relative">
            <button
                onClick={() => handleDropdownClick(name)}
                className="flex items-center gap-1 bg-[#2A2A2A] rounded-md px-3 py-2 text-gray-400 hover:bg-[#3A3A3A]"
            >
                {filters[name as keyof typeof filters] as string || label}
                {openDropdown === name ? <FiChevronUp /> : <FiChevronDown />}
            </button>

            {openDropdown === name && (
                <>
                    <div
                        className="fixed inset-0 z-10"
                        onClick={() => setOpenDropdown(null)}
                    />
                    <div className="absolute top-full left-0 mt-2 w-48 bg-[#2A2A2A] rounded-md shadow-lg z-20 py-1 border border-white/[0.08]">
                        {options.map((option) => (
                            <button
                                key={option.id}
                                onClick={() => handleOptionSelect(name, option.label)}
                                className="w-full text-left px-4 py-2 text-gray-400 hover:bg-[#3A3A3A] hover:text-white"
                            >
                                {option.label}
                            </button>
                        ))}
                    </div>
                </>
            )}
        </div>
    );

    const StatusButtons = () => (
        <div className="flex items-center gap-1 h-[32px]">
            {statuses.map(status => (
                <button
                    key={status.id}
                    onClick={() => handleStatusButtonClick(status.id)}
                    className={classNames(
                        'status-button',
                        status.id,
                        selectedStatuses.includes(status.id) ? 'active' : ''
                    )}
                >
                    {status.label}
                </button>
            ))}
        </div>
    );

    // Helper function to check if any filter is active
    const hasActiveFilters = () => {
        return (
            filters.status !== '' ||
            filters.customer !== '' ||
            filters.assignedTo !== '' ||
            filters.startDate !== null ||
            filters.endDate !== null ||
            filters.statuses.length > 0 ||
            filters.search !== ''
        );
    };

    return (
        <>
            <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-3">
                    <div className="relative">
                        <button
                            onClick={() => setShowDatePicker(!showDatePicker)}
                            className="flex items-center gap-1 bg-[#2A2A2A] rounded-md px-3 py-2 text-gray-400 hover:bg-[#3A3A3A]"
                        >
                            <FiCalendar />
                            {(filters.startDate && filters.endDate)
                                ? `${new Date(filters.startDate).toLocaleDateString()} - ${new Date(filters.endDate).toLocaleDateString()}`
                                : 'Duration'
                            }
                        </button>

                        {showDatePicker && (
                            <>
                                <div
                                    className="fixed inset-0 z-10"
                                    onClick={() => setShowDatePicker(false)}
                                />
                                <div className="absolute top-full left-0 mt-2 z-20 bg-[#2A2A2A] rounded-lg p-4 border border-white/[0.08]">
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={filters.startDate}
                                        endDate={filters.endDate}
                                        onChange={(update: [Date | null, Date | null]) => {
                                            setFilters((prev: any) => ({
                                                ...prev,
                                                startDate: update[0],
                                                endDate: update[1]
                                            }));
                                            if (update[0] && update[1]) {
                                                setShowDatePicker(false);
                                            }
                                        }}
                                        inline
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <StatusDropdown />

                    <Dropdown
                        name="customer"
                        options={customers}
                        label="Customer"
                    />

                    <Dropdown
                        name="assignedTo"
                        options={assignees}
                        label="Assign To"
                    />

                    <StatusButtons />

                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search tickets..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-64 bg-[#2A2A2A] border border-white/[0.08] rounded-md pl-10 pr-4 py-2 text-white placeholder-gray-400"
                            style={{
                                marginRight: '10px'
                            }}
                        />
                        <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                    </div>

                    {hasActiveFilters() && (
                        <button
                            onClick={clearFilters}
                            className="text-red-400 hover:text-red-300"
                        >
                            Clear all
                        </button>
                    )}
                </div>

                <div className="flex items-center gap-3">
                    <button
                        onClick={() => setIsCreateModalOpen(true)}
                        className="flex items-center gap-1 bg-[#2A2A2A] rounded-md px-4 py-2 text-white"
                    >
                        <FiPlus /> Add ticket
                    </button>
                    {/* <button className="flex items-center gap-1 bg-[#2A2A2A] rounded-md px-4 py-2 text-white">
                        ↓ Export
                    </button> */}
                </div>
            </div>


            <TicketModal
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                mode="create"
            />
        </>
    );
} 